<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="{isDetailDisplay:false}" @onDataSourceChange="onDataSourceChange" :queryParam="queryParam" @onPageChange="onPageChange">
            <el-table slot="elList" ref="skuListTable" :data="dataSource.Result" border @current-change="handleCurrentChange" highlight-current-row>
                <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                                 :key="index"
                                 :prop="col.FieldName"
                                 :label="col.DisplayName"
                                 :render-header="bindFilter(queryParam,col)"
                                 :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                 v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                    <template slot-scope="scope">
                        <span v-if="col.FieldName==='ProductNo'"><a @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</a></span>
                        <span v-else-if="col.FieldName==='DisplayProductStatus'">
                            <el-tag :close-transition="true" :type="scope.row[col.FieldName]=='正常' ? 'success' : scope.row[col.FieldName]=='停用' ?'danger':'gray'">{{ scope.row[col.FieldName] }}</el-tag>
                        </span>
                        <span v-else>  {{ scope.row[col.FieldName] }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </fixed-list>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.initialize();
        },
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                },
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    params: {}
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                status: {
                    selectedRow: {}
                },
                batchUpdateOptions: {
                    visible: false
                },
            }
        },
        props: {
            selectCondition: {
            }
        },
        watch: {
        },
        methods: {
            initialize: function () {
                this.onPageChange(this.queryParam);
            },
            onPageChange: function (param) {
                this.queryParam = this.Utils.extend(param, {
                    Params: {}
                });
                if (this.selectCondition) {
                    this.Utils.extend(this.queryParam.params, this.selectCondition);
                }
                this.$ajax.query("omsapi/productbom/getallbom", "post", param, data => {
                    this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
            }
        }
    }

</script>

<style>

</style>